import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ProjectService } from '../../core/services/project.service';
import { AuthenticationService } from '../../core/services/auth.service';
import { UserService } from '../../core/services/user.service';
import { DataService } from '../../core/services/data.service';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { SharedOperationsService } from '../../core/services/shared-operations.service';
import { CloudStorageService } from '../../core/services/cloud-storage.service';
import { gcp_project_id } from '../../../environments/environment';
// import { LanguageService } from '../../core/services/language.service';
import { EventService } from '../../core/services/event.service';
// import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
    standalone: false
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element;
  configData;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  userName;
  projectSelector: string='Please Select a Project';
  projectsData;

  projectId:string;
  onPlatform:boolean = true;

  projectNameSubscription: Subscription;
  userNameSubcription: Subscription;
  userPictureSubcription: Subscription;

  auth_User:any;
  userPhoto;

  @Inject(DOCUMENT) private document: any;

  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private ProjectService: ProjectService,
    // public languageService: LanguageService,
    // public translate: TranslateService,
    public authService: AuthenticationService,
    public userService: UserService,
    public _cookiesService: CookieService,
    private SharedOperationsService:SharedOperationsService,
    private CloudStorageService:CloudStorageService,
    private data: DataService,
    private event: EventService) {}

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.getUserProjects();

    this.checkPlafrom();
    this.getCurrentProjectIdAndName();

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.checkPlafrom();
        this.getCurrentProjectIdAndName();
      }
    });

    this.userPictureSubcription= this.data.currentUserPicture.subscribe(message=> this.userPhoto=message);

    this.userNameSubcription = this.data.currentUsername.subscribe(message => this.userName = message);

    this.getUserFullName().then((fullName) => {
      this.userName = fullName;
    });

    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    this.getUserPicture();
  }

  getCurrentProjectIdAndName(){
    const mainRoute = this.route["_routerState"].snapshot['url'].split('/')[1];
    const currentProjectId = this.route["_routerState"].snapshot['url'].split('/')[2];
    if(mainRoute == 'projects' && currentProjectId && currentProjectId != 'create'){
      this.projectId = currentProjectId;
      this.ProjectService.getProjectData(currentProjectId).then((project:any)=>{
        this.projectSelector = project.name ? project.name : 'Please Select a Project';
      })
      try {
        const getCurrentProject = this.projectsData.find(({id}) => id === currentProjectId);
        if(!getCurrentProject) {
          this.getUserProjects();
        }
      } catch (error) {
        // do nothing
      }
    }
  }

  checkPlafrom(){
    const mainRoute = this.route["_routerState"].snapshot['url'].split('/')[1];
    if(mainRoute == 'projects'){
      if(!this.onPlatform){
        this.onPlatform = true;
        this.applyMainSidebar();
      }
    }
    else{
      if(this.onPlatform){
        this.onPlatform = false;
        this.applySettingsSidebar();
      }
    }
  }

  goToProjectHome(){
    this.router.navigate([`/projects/${this.projectId}`]);
  }

  async getUserPicture(){
    const uid=this.SharedOperationsService.getAuthenticatedUser().uid;
    const path = `/users/${uid}/profile.jpg`;
    const bucket=`${gcp_project_id}.appspot.com`;
    try {
      const url = await this.CloudStorageService.getFileUrl(bucket,path)
      this.userPhoto = url;
    } catch (error) {
      this.userPhoto='assets/images/users/avatar-0.jpg';
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    // this.languageService.setLanguage(lang);
  }

  
  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  getUserProjects() {
    this.ProjectService.getUserProjects().then((projects) => {
      this.projectsData = projects;
    });
  }
  /**
   *  Get user first name and Last name
   */
  getUserFullName() {
    return this.userService.getUserData().then((user: any) => {
      const auth_user = this.SharedOperationsService.getAuthenticatedUser();
      const fullName = auth_user.firstName + " " + auth_user.lastName;
      return fullName;
    });
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.SharedOperationsService.clearCookieCash();
    this.SharedOperationsService.clearStorage();
    this.authService.logout();
    this.router.navigate(['/account/login']);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  onProjectClick(project: any) {
    // this.data.changeProjectName(project.name);
    this.SharedOperationsService.setKainicPidInLocalStorage(project.id);
    this.router.navigate([`projects/${project.id}`]);
  }

  onPlatformBtnClick(){
    const mainRoute = this.route["_routerState"].snapshot['url'].split('/')[1];
    const currentProjectId = this.route["_routerState"].snapshot['url'].split('/')[2];
    this.userService.getUserData().then((user)=>{
      let navigateTo = 'projects';
      if (mainRoute === 'projects' && currentProjectId && currentProjectId !== 'create') {
        this.SharedOperationsService.setKainicPidInLocalStorage(currentProjectId);
        if (user.projects[currentProjectId]) {
          navigateTo = `projects/${currentProjectId}`;
        }
      } else {
        const projectIdFromStorage = this.SharedOperationsService.getKainicPidFromLocalStorage();
        if (projectIdFromStorage && user.projects[projectIdFromStorage]) {
          navigateTo = `projects/${projectIdFromStorage}`;
        }
      }
      this.router.navigate([navigateTo]);
    })
  }

  onSettingsClicked(){
    this.router.navigate(["settings"]);
  }

  applySettingsSidebar(){
    this.event.broadcast('changeLayout','settings');
  }

  applyMainSidebar(){
    this.event.broadcast('changeLayout','vertical');
  }
  
}
