import { Component, OnInit, AfterViewInit } from '@angular/core';


@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    standalone: false
})
export class LayoutComponent implements OnInit, AfterViewInit {

  // layout related config
  // layoutType: string;

  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit() {
  }

}
